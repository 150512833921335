import React, { useState, useEffect } from "react";
import NavHeader from "../components/navigation/NavHeader";
import Footer from "../components/navigation/Footer";
import GoUpDown from "../components/navigation/GoUpDown";
import CommunityAccordion from "../components/community/CommunityAccordion";
import CommunityAccordionSkeleton from "../components/skeletons/CommunityAccordionSkeleton";
import AsideLeft from "../components/aside/AsideLeft";
import AsideRight from "../components/aside/AsideRight";
import JobListingCommunityAccordion from "../components/joblisting/JobListingCommunityAccordion";
import { getCommunities, getAnonymousCommunityBudges, getCommunityBudges } from "../core/communities";
import { getJobListing } from "../core/job_listing";


const Home = () => {

    const [communities, setCommunities] = useState([]);
    const [communityBudges, setCommunityBudges] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    // const [jobListing, setJobListing] = useState(null);

    let loggedInUserStr = sessionStorage.getItem("user");
    let loggedInUser = null;
    if (loggedInUserStr) {
        loggedInUser = JSON.parse(loggedInUserStr);
    }

    const fetchCommunities = async () => {
        setIsLoading(true);

        let communityBudges = {};
        if (loggedInUser !== null) {
            communityBudges = await getCommunityBudges();
            setCommunityBudges(communityBudges);
        }else{
            communityBudges = await getAnonymousCommunityBudges();
            setCommunityBudges(communityBudges);
        }

        const data = await getCommunities();
        setCommunities(data);
        setIsLoading(false);
    }

    useEffect(() => {

        fetchCommunities();

    }, []);

    const onPinCommunityChanged = async () => {
        fetchCommunities();
    }


    return (
        <div className=" min-h-full flex flex-col bg-primary-gray">
            <NavHeader currentPage={"forums"}  />
            <div className=" mx-auto max-w-6xl flex flex-col md:flex-row w-full items-start mt-[24px]  gap-x-5 px-[5px] md:px-4 py-[30px] md:py-11 ">
                <AsideLeft shows={'leaderboard'} />
                <AsideRight className="md:hidden" />
                <main className="flex-1 w-full">
                    <div className="flex-grow flex flex-col  ">
                        <div className="flex flex-col ">
                            
                            {isLoading && 
                                <>
                                    <CommunityAccordionSkeleton></CommunityAccordionSkeleton>
                                    <CommunityAccordionSkeleton items={2}></CommunityAccordionSkeleton>
                                    <CommunityAccordionSkeleton items={3}></CommunityAccordionSkeleton>
                                </>
                            }

                            {/* {jobListing &&
                                <JobListingCommunityAccordion
                                    community={jobListing}
                                    isOpenInitially={true}
                                    key={'jobslisting-community'}
                                    limit={5}
                                />
                            } */}
                            
                            {communities.map((community, index) => {
                                //the public only sees communities with forums
                                if(community?.forums?.length === 0 && loggedInUser?.role === "admin"){
                                    return <CommunityAccordion
                                        community={community}
                                        isOpenInitially={true}
                                        key={index}
                                        role={loggedInUser?.role}
                                        position={index+1}
                                        onPinCommunityChanged={onPinCommunityChanged}
                                        communityBudges={communityBudges}
                                    />
                                }else if (community?.forums?.length > 0 ){
                                    return <CommunityAccordion
                                        community={community}
                                        isOpenInitially={true}
                                        key={index}
                                        role={loggedInUser?.role}
                                        position={index+1}
                                        onPinCommunityChanged={onPinCommunityChanged}
                                        communityBudges={communityBudges}
                                    />
                                }else{
                                    return <div key={index}  ></div>
                                }
                            })}
                        </div>
                    </div>
                </main>
                <AsideRight className="hidden md:block" />
            </div>
            <Footer/>
            <GoUpDown />
        </div>
    );
};

export default Home;

