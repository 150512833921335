import { validateAndSend } from "../index";

import {
    getNewsFeedEndPoint
} from "../endPoints";


export const fetchNextFeed = async (page) => {
    return validateAndSend({
        endPoint: getNewsFeedEndPoint(page),
        emit: true
    });
}
