import { inviteEndPoint, verifyInviteLinkParamsEndPoint, acceptInvitationEndPoint, generateShareLinkEndPoint, verifyJoinLinkParamsEndPoint, joinViaLinkEndPoint, leaderboardEndPoint} from "../endPoints";
import { inviteRules } from "./inviteRules";
import { verifyInviteRules } from "./verifyInviteRules";
import { acceptInvitationRules } from "./acceptInvitationRules";
import { validateAndSend } from "../index";
import Cookies from "js-cookie";
import { verifyJoinLinkRules } from "./verifyJoinLinkRules"

const COOKIE_NAME = process.env.REACT_APP_COOKIE_NAME || "";

export const verifyInviteParams = async (inputs) => {
    return validateAndSend({
        endPoint:  verifyInviteLinkParamsEndPoint,
        inputs: inputs,
        rules: verifyInviteRules,
        formData: {
            ... inputs
        },
        emit: true
    });
};

export const invite = async (inputs) => {
    return validateAndSend({
        endPoint:  inviteEndPoint,
        inputs: inputs,
        rules: inviteRules,
        formData: {
            invited_email: inputs.email,
            invited_name: inputs.name,
            message: inputs.message
        }
    });
};


export const acceptInvitation = async (inputs) => {
    return validateAndSend({
        endPoint:  acceptInvitationEndPoint,
        inputs: inputs,
        rules: acceptInvitationRules,
        formData: {
            name: `${inputs.firstName} ${inputs.lastName}`,
            username: inputs.username,
            email: inputs.email,
            password: inputs.password,
            comfirm_password: inputs.password2,
            avatar: inputs.avatar,
            location: {
                city: inputs.city,
                state: inputs.state,
                zipcode: inputs.zipcode
            },
            invitation_link: inputs.invitationLink,
            code: inputs.code,
            verified_token: inputs.verifiedToken
        },
        onSuccess: (response) => {
            sessionStorage.setItem("user", JSON.stringify(response));
            const USER_COOKIE = `${COOKIE_NAME}-${response._id}`;
            Cookies.set(USER_COOKIE, response.authentication.sessionToken);
        }
    });
};

export const generateSocialInviteLink = async () => {
    return validateAndSend({
        endPoint:  generateShareLinkEndPoint,
        onSuccess: (response) => {
            let userJson = sessionStorage.getItem("user");
            let user = JSON.parse(userJson);
            user.generated_public_invite_link = response.url;
            userJson = JSON.stringify(user);
            sessionStorage.setItem("user", userJson);
        }
    });
};


export const verifyJoinLink = async (inputs) => {
    return validateAndSend({
        endPoint:  verifyJoinLinkParamsEndPoint,
        inputs: inputs,
        rules: verifyJoinLinkRules,
        formData: {
            ... inputs
        },
        emit: true
    });
};


export const joinViaLink = async (inputs) => {
    return validateAndSend({
        endPoint:  joinViaLinkEndPoint,
        inputs: inputs,
        rules: acceptInvitationRules,
        formData: {
            name: `${inputs.firstName} ${inputs.lastName}`,
            username: inputs.username,
            email: inputs.email,
            password: inputs.password,
            comfirm_password: inputs.password2,
            avatar: inputs.avatar,
            location: {
                city: inputs.city,
                state: inputs.state,
                zipcode: inputs.zipcode
            },
            invitation_link: inputs.invitationLink
        }
    });
};

export const getLeaderboard = async() => {
    return validateAndSend({
        endPoint:  leaderboardEndPoint,
        emit: true
    });
}