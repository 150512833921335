import React, { useState, useEffect } from "react";
import Avatar from "../components/icons/Avatar"
import NavHeader from "../components/navigation/NavHeader";
import Footer from "../components/navigation/Footer";
import GoUpDown from "../components/navigation/GoUpDown";
import CommunityAccordion from "../components/community/CommunityAccordion";
import CommunityAccordionSkeleton from "../components/skeletons/CommunityAccordionSkeleton";
import AsideLeft from "../components/aside/AsideLeft";
import AsideRight from "../components/aside/AsideRight";
import JobListingCommunityAccordion from "../components/joblisting/JobListingCommunityAccordion";
import { getCommunities, getAnonymousCommunityBudges, getCommunityBudges } from "../core/communities";
import { getJobListing } from "../core/job_listing";
import { fetchNextFeed } from "../core/newsfeed";
import { useNavigate } from "react-router-dom";
import PinIcon from "../components/icons/Pin";
import ChatsIcon from "../components/icons/Chats";
import ReactTimeAgo from 'react-time-ago';
import DOMPurify from "dompurify";
import LikeIcon from "../components/icons/Like";
import { likePost, likeReplyComment } from "../core/communities";
import DeleteIcon from "../components/icons/Delete"



const NewsFeed = () => {

    const navigate = useNavigate();

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalCount, setTotalCount] = useState(0);
    const [posts, setPosts] = useState([]);

    const [communityBudges, setCommunityBudges] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    // const [jobListing, setJobListing] = useState(null);

    let loggedInUserStr = sessionStorage.getItem("user");
    let loggedInUser = null;
    if (loggedInUserStr) {
        loggedInUser = JSON.parse(loggedInUserStr);
    }

    const fetchFeed = async (pageToLoad) => {
        setIsLoading(true);

        // if(posts.length == 0){
        //     let communityBudges = {};
        //     if (loggedInUser !== null) {
        //         communityBudges = await getCommunityBudges();
        //         setCommunityBudges(communityBudges);
        //     }else{
        //         communityBudges = await getAnonymousCommunityBudges();
        //         setCommunityBudges(communityBudges);
        //     }
        // }

        const feedsResult = await fetchNextFeed(pageToLoad);
        // console.log("The feedsResult ", feedsResult);
        const newFeedsList = [
            ...posts,
            ...feedsResult.data
        ];
        console.log("newFeedsList: ", newFeedsList);
        setPosts(newFeedsList);
        setPage(pageToLoad);

        setIsLoading(false);
    }

    useEffect(() => {

        fetchFeed(page);

    }, []);

    function loadMore(e){
        e.preventDefault();
        const nextPage = page + 1;
        fetchFeed(nextPage);
    }

    const onPinCommunityChanged = async () => {
        // fetchCommunities();
    }

    const goToProfile = (e, user) => {
        e.preventDefault();
        navigate(`/profile/${user._id}`);
    };

    const handleClickScroll = (id) => {
        const element = document.getElementById(id);
        if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const likePostHelper = async (post, iLikedAlready) => {
        const inputs = {
            id: post._id
        };
        let response = await likePost(inputs);
        onUserLikedPost(post, loggedInUser, iLikedAlready);
    }

    const handleLike = async (e) => {
        e.preventDefault();
        if (loggedInUserStr) {
            likePostHelper();
        } else {
            // navigate(`/login`);
            setAlertModalMessage("Login to like");
            toggleLoginAlertModalModal();
        }
    }

    function onUserLikedPost() {
        //todo
    }

    function onOpenDeletePost() {
        //todo
    }

    const [alertModalMessage, setAlertModalMessage] = useState("Your need to be logged in");
    const [isLoginAlertModalOpen, setIsLoginAlertModalOpen] = useState(false);
    const toggleLoginAlertModalModal = () => {
        setIsLoginAlertModalOpen(!isLoginAlertModalOpen);
    };



    return (
        <div className=" min-h-full flex flex-col bg-primary-gray">
            <NavHeader currentPage={"forums"} />
            <div className=" mx-auto max-w-6xl flex flex-col md:flex-row w-full items-start mt-[24px]  gap-x-5 px-[5px] md:px-4 py-[30px] md:py-11 ">
                <AsideLeft shows={'leaderboard'} />
                <main className="flex-1 w-full">
                    <div className="flex-grow flex flex-col  ">
                        <div className="flex flex-col ">

                            {isLoading &&
                                <>
                                    <CommunityAccordionSkeleton></CommunityAccordionSkeleton>
                                    <CommunityAccordionSkeleton items={2}></CommunityAccordionSkeleton>
                                    <CommunityAccordionSkeleton items={3}></CommunityAccordionSkeleton>
                                </>
                            }

                            {posts.map((post, index) => {
                                let createdAt = post.createdAt
                                if (typeof createdAt != 'string') {
                                    createdAt = (new Date()).toDateString();
                                }

                                const isOwner = post.user._id == loggedInUser?._id;

                                let iLikedAlready = false;
                                for (let index = 0; loggedInUser && index < post?.likes.length; index++) {
                                    const like = post.likes[index];
                                    if (like._id === loggedInUser?._id) {
                                        iLikedAlready = true;
                                        break;
                                    }

                                }

                                let repliesCount = 0;
                                if (post.replies) {
                                    repliesCount = post.replies.length;
                                }

                                // let details = post.description;
                                // const decodedDetails = atob(details);
                                // const decodedHtml = DOMPurify.sanitize(decodedDetails, {
                                //     USE_PROFILES: { html: true },
                                // });
                                // const cleanHtml = decodeURIComponent(decodedHtml);

                                // let cleanReferencedHtml = "";
                                // let hasReferencedReply = false;
                                // let refUserName = "";
                                // if (post.referenced_reply) {
                                //     hasReferencedReply = true;
                                //     try {
                                //         const refDecodedDetails = atob(post.referenced_reply.details);
                                //         const refDecodedHtml = DOMPurify.sanitize(refDecodedDetails, {
                                //             USE_PROFILES: { html: true },
                                //         });
                                //         cleanReferencedHtml = decodeURIComponent(refDecodedHtml);
                                //         // if (Object.hasOwnProperty.call(userRefs, post.referenced_reply.user)) {
                                //         //     refUserName = userRefs[post.referenced_reply.user].username;
                                //         // }
                                //     } catch (error) {
                                //         console.log("this error", post.referenced_reply);
                                //         cleanReferencedHtml = post.referenced_reply.details;
                                //     }

                                // }


                                return (
                                    <div key={index} className={`flex flex-col max-w-vw w-full bg-white rounded-md mt-1 md:mt-3`}>
                                        <div className={`flex bg-primary-white ${index > 0 ? "rounded-md" : ""}  flex-row  p-3`}>
                                            <Avatar user={post.user} />
                                            <div className="flex flex-col ml-2 flex-grow justify-between">
                                                <div className="flex flex-row items-center w-full ">
                                                    <a className="flex flex-row  items-center justify-between  w-full  hover:underline cursor-pointer">
                                                        <span onClick={(e) => goToProfile(e, post.user)} className="font-bold text-black ml-1 text-md leading-[0.8]">
                                                            {post.user.username}
                                                        </span>
                                                        {/* {index != -1 &&
                                                            <span className="text-xs font-sans text-black ml-1 text-md text-gray-600 leading-none">
                                                                #{index + 1}
                                                            </span>
                                                        } */}
                                                    </a>
                                                </div>

                                                <div className="flex flex-row items-center">
                                                    <PinIcon className={'text-purple-900'} />
                                                    <span className="text-xs ml-1">
                                                        {post.user.location.city}, {post.user.location.state}
                                                    </span>
                                                </div>
                                                <div className="hidden flex flex-row items-center">
                                                    {/* <a className="flex flex-row  hover:underline cursor-pointer">
                                                    <ChatsIcon fillColor={'#581c88'} strokeColor={'#ffffff'} className={'w-4'} />
                                                    <span className="text-xs ml-1 ">
                                                        {Utils.comma(post.user.count_of_replies)}
                                                    </span>
                                                </a> */}
                                                    {/* <UserIcon className={'text-purple-900'} /> */}
                                                    {/* <span className="text-xs ml-1"> member since: </span>
                                                <span className="text-xs ml-2">
                                                    {Utils.formatDate(post.user.join_date.split("T")[0], "month date, year")}
                                                </span> */}
                                                </div>
                                                <div className="text-left flex flex-row text-gray-500 text-xs font-sans ">
                                                    <ChatsIcon fillColor={'#581c88'} strokeColor={'#ffffff'} className={'w-4 mr-1 self-start'} />
                                                    <div className="mr-1">Posted </div>
                                                    <div className="flex whitespace-normal flex-wrap">
                                                        {/* {` ${Utils.formatDate(post.createdAt, "month date, year")}`} */}
                                                        <ReactTimeAgo date={createdAt} locale="en-US" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`flex flex-col bg-white border-t-gray-200 border-t rounded-b-md p-3`} >
                                            <div className="mb-2 text-sm font-sans text-gray-400 flex flex-row justify-between items-center  border-b border-gray-100">
                                                <span className="text-left text-gray-800 text-lg font-bold pb-2" >
                                                    {/* {Utils.formatDate(post.createdAt, "month date, year")} */}
                                                    {post.title}
                                                </span>
                                                <span>
                                                    {/* #{post._id} */}
                                                </span>
                                            </div>

                                            <div>
                                                {/* <div className="flex flex-col " >
                                                    <article className="overflow-scroll">
                                                        {hasReferencedReply === false &&
                                                            <blockquote
                                                                className="font-sans messageText SelectQuoteContainer ugc baseHtml fs-14px text-[#383938]"
                                                                dangerouslySetInnerHTML={{ __html: cleanHtml }}>
                                                            </blockquote>
                                                        }
                                                        {hasReferencedReply === true &&
                                                            <blockquote
                                                                className="font-sans messageText SelectQuoteContainer ugc baseHtml fs-14px text-[#383938]">
                                                                {!post.referenced_reply.is_marked_for_deleting &&
                                                                    <div className="bbCodeBlock bbCodeQuote" data-author="msdesignltd">
                                                                        <aside>
                                                                            <div className="attribution type">
                                                                                Replying to
                                                                                <a className="ml-1 hover:underline cursor-pointer mr-2"><b>{refUserName}</b></a>

                                                                                <span onClick={(e) => handleClickScroll(`#reply-${post.referenced_reply._id}`)} className="AttributionLink">↑</span>
                                                                            </div>
                                                                            <blockquote className="quoteContainer">
                                                                                <div className="quote" dangerouslySetInnerHTML={{ __html: cleanReferencedHtml }} ></div>
                                                                            </blockquote>
                                                                        </aside>
                                                                    </div>
                                                                }
                                                                {post.referenced_reply.is_marked_for_deleting &&
                                                                    <div className="bbCodeBlock bbCodeQuote" data-author="msdesignltd"  >
                                                                        Quoted comment has been removed.
                                                                    </div>
                                                                }
                                                                <div style={{ marginLeft: "10px" }} dangerouslySetInnerHTML={{ __html: cleanHtml }}></div>
                                                            </blockquote>
                                                        }
                                                    </article>
                                                </div> */}
                                                <div className="flex flex-col w-full py-5"  >
                                                    <img src={post.media} className="w-full" />
                                                </div>
                                                <div className="rounded-b-md flex flex-row  py-2 mt-5 px-2 text-sm font-sans items-center justify-end border-t border-gray-100">
                                                    <button role="button"
                                                        type="button"
                                                        aria-label="view post details button"
                                                        className="flex justify-center items-center flex-row gap-x-2  w-100  rounded-sm bg-purple-900 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-purple-800  disabled:cursor-not-allowed"
                                                    >
                                                        View Post
                                                    </button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                )
                            })}

                            <button
                                onClick={loadMore}
                                 role="button"
                                type="button"
                                aria-label="view post details button"
                                className="my-2 flex justify-center items-center flex-row gap-x-2  w-100  rounded-sm bg-purple-900 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-purple-800  disabled:cursor-not-allowed"
                            >
                                Load More ...
                            </button>
                        </div>
                    </div>
                </main>
                <AsideRight />
            </div>
            <Footer />
            <GoUpDown />
        </div>
    );
};

export default NewsFeed;

