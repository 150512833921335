import { validateAndSend } from "../index";
import { isAuthorised } from "../../utils/auth";

import {
    getCommunitiesEndPoint,
    getAllCommunitiesEndPoint,
    getForumDetailsEndPoint,
    getPostDetailsEndPoint,
    addCommunityRequestEndPoint,
    getCommunityRequestsEndPoint,
    searchCommunityRequestsEndPoint,
    rejectCommunityRequestsEndPoint,
    acceptCommunityRequestsEndPoint,
    getCommunityEndPoint,
    updateCommunityEndPoint,
    deleteCommunityEndPoint,
    addFormumEndPoint,
    updateForumEndPoint,
    deleteForumEndPoint,
    addPostEndPoint,
    updatePostEndPoint,
    deletePostEndPoint,
    likePostEndPoint,
    postCommentEndPoint,
    likeCommentEndPoint,
    updateCommentEndPoint,
    deleteCommentEndPoint,
    pinCommunityEndPoint,
    unPinCommunityEndPoint,
    searchGeneralEndPoint,
    pinPostEndPoint,
    unPinPostEndPoint,
    getJobsBudgesLastTimeEndPoint,
    updateJobsBudgesLastTimeEndPoint,
    getCommunitiesBudgesEndPoint,
    getAnonymousBudgesEndPoint,
    updateCommunitiesBudgesEndPoint,
} from "../endPoints";

import { addCommunityRequestRules } from "../communities/addCommunityRequestRules";
import { searchCommunityRules } from "../communities/searchCommunitiesRules";
import { rejectCommunityRequestRules } from "./rejectCommunityRequestRules";
import { acceptCommunityRequestRules } from "./acceptCommunityRequestRules";
import { updateCommunityRules } from "./updateCommunityRules";
import { addForumRules } from "./addForumRules";
import { updateForumRules } from "./updateForumRules";
import { addPostRules } from "./addPostRules";
import { postCommentRules, updateCommentRules } from "./postCommentRules";

export const getCommunities = async () => {
    const endPoint = isAuthorised(["admin"]) ? getAllCommunitiesEndPoint : getCommunitiesEndPoint;
    return validateAndSend({
        endPoint: endPoint,
        emit: true
    });
}

export const getForum = async (id, order="desc") => {
    const endPoint = getForumDetailsEndPoint(id, order);
    return validateAndSend({
        endPoint: endPoint,
        emit: true
    });
}

export const updateForum = async (inputs) => {
    return validateAndSend({
        endPoint: updateForumEndPoint,
        inputs: inputs,
        rules: updateForumRules,
        formData: {
            id: inputs.id,
            title: inputs.title,
            description: inputs.description,
            display_order: inputs.order,
            status: inputs.status,
            icon: inputs.icon
        }
    });
}

export const deleteForum = async (inputs) => {
    return validateAndSend({
        endPoint: deleteForumEndPoint,
        formData: {
            id: inputs.id
        }
    });
}

export const getPost = async (id, order="desc") => {
    const endPoint = getPostDetailsEndPoint(id, order);
    return validateAndSend({
        endPoint: endPoint,
        emit: true
    });
}

export const addPost = async(inputs) => {
    return validateAndSend({
        endPoint: addPostEndPoint,
        inputs: inputs,
        rules: addPostRules,
        formData: {
            forum_id: inputs.forumId,
            title: inputs.title,
            sub_title: inputs.subTitle,
            details: btoa(encodeURIComponent(inputs.details)),
            media: inputs.media
        }
    });
}

export const updatePost = async(inputs) => {
    return validateAndSend({
        endPoint: updatePostEndPoint,
        inputs: inputs,
        rules: addPostRules,
        formData: {
            id: inputs.id,
            title: inputs.title,
            sub_title: inputs.subTitle,
            details: btoa(encodeURIComponent(inputs.details)),
            media: inputs.media
        }
    });
}

export const deletePost = async(inputs) => {
    return validateAndSend({
        endPoint: deletePostEndPoint,
        formData: {
            id: inputs.id
        }
    });
}

export const postComment = async(inputs) => {
    return validateAndSend({
        endPoint: postCommentEndPoint,
        inputs: inputs,
        rules: postCommentRules,
        formData: {
            post_id: inputs.id,
            details: btoa(encodeURIComponent(inputs.commentDetails)),
            referenced_reply: inputs.referencedReplyId
        },
        emit: true
    });
}

export const updateComment = async(inputs) => {
    return validateAndSend({
        endPoint: updateCommentEndPoint,
        inputs: inputs,
        rules: updateCommentRules,
        formData: {
            reply_id: inputs.id,
            details: btoa(encodeURIComponent(inputs.commentDetails))
        },
        emit: true
    });
}

export const deleteComment = async(inputs) => {
    return validateAndSend({
        endPoint: deleteCommentEndPoint,
        inputs: inputs,
        formData: {
            reply_id: inputs.id
        },
        emit: true
    });
}

export const likePost = async(inputs) => {
    return validateAndSend({
        endPoint: likePostEndPoint, 
        inputs: inputs,
        formData: {
            post_id: inputs.id
        }
    });
}

export const likeReplyComment = async(inputs) => {
    return validateAndSend({
        endPoint: likeCommentEndPoint, 
        inputs: inputs,
        formData: {
            reply_id: inputs.id
        }
    });
}



export const addCommunityRequest = async (inputs) => {
    return validateAndSend({
        endPoint: addCommunityRequestEndPoint,
        inputs: inputs,
        rules: addCommunityRequestRules,
        formData: {
            ...inputs
        }
    });
}

export const getCommunityRequests = async () => {
    return validateAndSend({
        endPoint: getCommunityRequestsEndPoint,
        emit: true
    });
}

export const searchCommunityRequests = async (inputs) => {
    return validateAndSend({
        endPoint: searchCommunityRequestsEndPoint,
        inputs: inputs,
        rules: searchCommunityRules,
        formData: {
            ...inputs
        },
        emit: true
    });
}

export const rejectCommunityRequest = async (inputs) => {
    return validateAndSend({
        endPoint: rejectCommunityRequestsEndPoint,
        inputs: inputs,
        rules: rejectCommunityRequestRules,
        formData: {
            ...inputs
        }
    });
}

export const acceptCommunityRequest = async (inputs) => {
    return validateAndSend({
        endPoint: acceptCommunityRequestsEndPoint,
        inputs: inputs,
        rules: acceptCommunityRequestRules,
        formData: {
            id: inputs.id,
            reason: inputs.reason,
            new_community_title: inputs.title,
            new_community_description: inputs.description,
            new_community_display_order: inputs.order
        }
    });
}

export const getCommunity = async (id) => {
    return validateAndSend({
        endPoint: getCommunityEndPoint(id),
        emit: true
    });
}

export const updateCommunity = async (inputs) => {
    return validateAndSend({
        endPoint: updateCommunityEndPoint,
        inputs: inputs,
        rules: updateCommunityRules,
        formData: {
            id: inputs.id,
            title: inputs.title,
            description: inputs.description,
            display_order: inputs.order,
            status: inputs.status
        }
    });
}

export const deleteCommunity = async (inputs) => {
    return validateAndSend({
        endPoint: deleteCommunityEndPoint,
        formData: {
            id: inputs.id
        }
    });
}

export const addForumToCommunity = async (inputs) => {
    return validateAndSend({
        endPoint: addFormumEndPoint,
        inputs: inputs,
        rules: addForumRules,
        formData: {
            community_id: inputs.id,
            title: inputs.title,
            icon: "", //todo: implement ability to choose an icon
            description: inputs.description,
            display_order: inputs.order,
            status: inputs.status
        },
        emit: true
    });
}

export const pinCommunity = async (inputs) => {
    return validateAndSend({
        endPoint: pinCommunityEndPoint,
        formData: {
            id: inputs.id
        }
    });
}

export const unPinCommunity = async (inputs) => {
    return validateAndSend({
        endPoint: unPinCommunityEndPoint,
        formData: {
            id: inputs.id
        }
    });
}

export const generalSearch = async (inputs) => {
    return validateAndSend({
        endPoint: searchGeneralEndPoint,
        inputs: inputs,
        rules: searchCommunityRules,
        formData: {
            ...inputs
        },
        emit: true
    });
}

export const pinPost = async (inputs) => {
    return validateAndSend({
        endPoint: pinPostEndPoint,
        formData: {
            id: inputs.id
        }
    });
}

export const unPinPost = async (inputs) => {
    return validateAndSend({
        endPoint: unPinPostEndPoint,
        formData: {
            id: inputs.id
        }
    });
}

export const getJobsBudgesLastTime = async () => {
    return validateAndSend({
        endPoint: getJobsBudgesLastTimeEndPoint,
        emit: true
    });
}

export const updateJobsBudgesLastTime = async () => {
    return validateAndSend({
        endPoint: updateJobsBudgesLastTimeEndPoint,
        emit: true
    });
}

export const getCommunityBudges = async () => {
    return validateAndSend({
        endPoint: getCommunitiesBudgesEndPoint,
        emit: true
    });
}

export const getAnonymousCommunityBudges = async () => {
    let localTrack = localStorage.getItem("forumsTracker");
    if(!localTrack){
        //the first time anonymous is accessing these budges
        localTrack = "{}"; 
    }
    return validateAndSend({
        endPoint: getAnonymousBudgesEndPoint,
        emit: true,
        formData: {
            forumsTrackerString: localTrack
        }
    });
}

export const updateCommunitiesBudges = async (id) => {
    return validateAndSend({
        endPoint: updateCommunitiesBudgesEndPoint,
        emit: true,
        formData: {
            id: id
        }
    });
}