import React, { useState, useEffect } from "react";
import { getLeaderboard } from "../../core/invite";
import NavUserAvatar from "../navigation/NavUserAvatar";
import LeaderBoardSkeleton from "../skeletons/LeaderBoardSkeleton";
import { useNavigate } from "react-router-dom";
import LoginAlertModal  from "../auth/LoginAlertModal"

const LeaderBoard = () => {

    const navigate = useNavigate();

    const [leaderboard, setLeaderboard] = useState([]);
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {

        const fetchLeaderboard = async () => {
            setIsLoading(true);
            const data = await getLeaderboard();
            setLeaderboard(data);
            setIsLoading(false);
        }

        fetchLeaderboard();

    }, []);

    let loggedInUserStr = sessionStorage.getItem("user");
    let loggedInUser = null;
    if (loggedInUserStr) {
        loggedInUser = JSON.parse(loggedInUserStr);
    }

    const [isLoginAlertModalOpen, setIsLoginAlertModalOpen] = useState(false);
    const toggleLoginAlertModalModal = () => {
        setIsLoginAlertModalOpen(!isLoginAlertModalOpen);
    };

    const goToProfile = (e, user) => {
        e.preventDefault();
        console.log("here");
        if (loggedInUser) {
            navigate(`/profile/${user._id}`);
        }else{
            toggleLoginAlertModalModal()
        }
    };

    return (
        <div className="flex flex-col h-fit md:h-[576px] w-full ">
            {isLoading && <LeaderBoardSkeleton />}
            {leaderboard.length > 0 &&
                <>
                    <div className="text-center font-bold py-2 border-b rounded-md border-gray-200 md:border-0 bg-primary-white">
                        Invitation Leaders
                    </div>

                    <div

                        className="no-scroll-barx flex md:block overflow-auto  md:divide-y divide-primary-gray-100  shadow-sm sm:ring-0 md:ring-1 ring-gray-900/5 sm:rounded-sm"
                    >
                        {leaderboard.map((user, index) => (
                            <div key={index} className="md:min-w-[120px] p-2 relative flex flex-row gap-x-2 mr-2 md:mr-0 justify-start hover:bg-primary-gray-50  ">


                                <NavUserAvatar user={user} link={"#"} imgSizeClass="w-[48px] h-[48px] max-w-[48px] max-h-[48px] block" />


                                <div className=" flex flex-col justify-between">
                                    <div className="text-xs font-semibold leading-6x text-primary-gray-900">
                                        <div onClick={(e) => goToProfile(e, user)} className="whitespace-nowrap cursor-pointer">
                                            <div>{user.username}</div>
                                            {/* <div className="absolute inset-x-2 top-[4px] bottom-0 font-bold w-fit" >
                                                {index+1}
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className="mt-1 flex text-sm leading-1 text-gray-500 whitespace-nowrap">
                                        {user.statistics?.total_points} {`invite${user.statistics?.total_points > 1 ? "s" : ""}`}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            }
            <LoginAlertModal isOpen={isLoginAlertModalOpen} closeFun={setIsLoginAlertModalOpen} />
        </div>
    );
};

export default LeaderBoard;