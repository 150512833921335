import React from "react";
import RssFeeds from "../rss/RssFeeds";
import { sources } from "../rss/RssSources";
import { Utils } from "../../utils";


const AsideRight = ({className=""}) => {

    Utils.shuffle(sources);

    return (
        <aside className={"mb-[5px] md:mb-0 rounded-md md:sticky top-16 w-full md:w-56 shrink-0 flex flex-row lg:flex-col overflow-auto lg:overflow-['unset']  bg-white p-0 md:p-1  " + className}>
            <div className="text-center font-bold py-2 mb-1 hidden md:block border-b md:border-0x border-gray-200 rounded-t-md  bg-primary-white">
                Latest About Health
            </div>
            {sources.map(source => {
                return (<RssFeeds source={source} />)
            })}
        </aside>
    );
};



export default AsideRight;