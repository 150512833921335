import React, { useState } from "react";
import ReactTimeAgo from 'react-time-ago'
import ChatsIcon from "../icons/Chats"
import LikeIcon from "../icons/Like";
import { Utils } from "../../utils";
import toast from 'react-hot-toast';
import { pinPost, unPinPost } from "../../core/communities";
import Pinned from "../icons/Pinned";
import { useNavigate } from "react-router-dom";

const PostListItem = ({ post, communityId, forumId, onPinPostChanged, role, isSearchResult = false, postBudges = [] }) => {
    const hasAvatar = post.user && post.user.avatar && post.user.avatar.trim().length > 0;

    const navigate = useNavigate();

    if (!onPinPostChanged) {
        onPinPostChanged = () => { };
    }

    if (!role) {
        role = "";
    }

    const [pinErrors, setPinErrors] = useState([]);
    const [isPinLoading, setIsPinLoading] = useState(false);
    const handlePinPost = async (e, postId) => {
        e.preventDefault();
        setPinErrors([]);
        setIsPinLoading(true);
        const inputs = {
            id: post._id
        };
        let response = null;
        response = await pinPost(inputs);
        setIsPinLoading(false);
        if (response !== true) {
            return setPinErrors(response);
        }
        toast.success('Post was pinned successfully', {
            icon: '✅'
        });
        onPinPostChanged(post._id);
    }

    const handleUnPinPost = async (e, postId) => {
        e.preventDefault();
        setPinErrors([]);
        setIsPinLoading(true);
        const inputs = {
            id: post._id
        };
        let response = null;
        response = await unPinPost(inputs);
        setIsPinLoading(false);
        if (response !== true) {
            return setPinErrors(response);
        }
        toast.success('Post was unpinned successfully', {
            icon: '✅'
        });
        onPinPostChanged(post._id);
    }

    const handleGoToPost = (e) => {
        e.preventDefault();
        navigate(`/community/${communityId}/forum/${forumId}/post/${post._id}`);
    }

    return (
        <div onClick={handleGoToPost} className={`relative last:rounded-b-md flex flex-1 w-full flex-grow p-3 mb-0 border-b border-gray-100 even:bg-white odd:bg-slate-100 ${post?.is_pinned ? "pinned-item" : ""}  cursor-pointer ${isSearchResult ? "hover:bg-purple-100" : "'"} `} >
            {(postBudges.indexOf(post._id) >= 0) &&
                <div className={`absolute text-black text-xs p-1 rounded-sm ${role === "admin"?"right-28 top-14 md:top-10":"right-5 md:right-8 top-3"}  `}>
                    {/* <ChatsIcon /> */}
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 0 1 .865-.501 48.172 48.172 0 0 0 3.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z" />
                    </svg>


                    <span className={`bg-red-600 text-white h-[12px]  flex items-center absolute p-1  rounded-full -right-[10px] top-0 `} style={{ fontSize: "10px" }} >
                        new
                    </span>
                </div>
            }
            <div>
                {hasAvatar &&
                    <img
                        className="inline-block  w-[48px] h-[48px] max-w-[48px] max-h-[48px] rounded-sm"
                        src={post.user.avatar}
                        alt=""
                    />
                }
                {hasAvatar == false &&
                    <div className="flex items-center justify-center h-14 w-14 min-h-[3.5rem] min-w-[3.5rem] rounded-sm uppercase font-bold text-3xl font-sans"
                        style={{ backgroundColor: post.user.name_initials_bg_color, color: post.user.name_initials_text_color }}
                    >
                        {post.user.name[0]}
                    </div>
                }
            </div>
            <div className="flex flex-col ml-2 flex-grow justify-between ">
                <div className="flex w-full flex-col justify-between ">
                    <div className="w-full flex flex-row items-center self-stretch justify-between self-start md:leading-none">
                        <a className="font-semibold flex items-center text-sm md:text-md text-purple-900 hover:underline cursor-pointer leading-none self-start"
                            href={`/community/${communityId}/forum/${forumId}/post/${post._id}`}>
                            {post?.is_pinned &&
                                <div className="mr-1 text-sm text-red-600">
                                    <Pinned className="w-[15px]" />
                                </div>
                            }
                            {post.title}
                        </a>
                        <div className="">
                            {role === "admin" &&
                                <>
                                    {!post?.is_pinned &&
                                        <button role="button" 
                                            onClick={(e) => { e.stopPropagation(); handlePinPost(e, post._id) }}
                                            className="flex items-center justify-center rounded-sm bg-purple-900 hover:bg-purple-800 p-1 w-[100px] text-xs font-semibold  text-white active:text-white/80 btn-gradient-main cursor-pointer" >

                                            Pin
                                        </button>
                                    }

                                    {post?.is_pinned === true &&
                                        <button role="button" 
                                            onClick={(e) => { e.stopPropagation(); handleUnPinPost(e, post._id) }}
                                            className="flex items-center justify-center rounded-sm bg-purple-900 hover:bg-purple-800 p-1 w-[100px] text-xs font-semibold  text-white active:text-white/80 btn-gradient-main cursor-pointer" >

                                            Unpin
                                        </button>
                                    }
                                </>
                            }
                        </div>
                    </div>

                    {/* <div className="flex flex-row items-start" >
                        {post.sub_title.length > 0 &&
                            <div className="text-sm md:text-xs flex " > 
                                {post.sub_title}
                            </div>
                        }
                    </div> */}



                </div>
                <div className="flex flex-row items-start justify-start" >
                    <a onClick={(e) => { e.stopPropagation(); navigate(`/profile/${post.user._id}`); }} className="hover:underline text-sm md:text-xs cursor-pointer  font-semibold md:font-bold xmd:leading-[0]">{post.user.username},</a>
                </div>
                <div className="flex flex-row w-full justify-between items-center ">
                    <div className="flex flex-row justify-start self-start mr-10 mb-0  ">
                        <div className="whitespace-nowrap flex flex-col items-start">
                            {/* <a className="hover:underline text-sm md:text-xs cursor-pointer font-bold leading-[0]">{post.user.username},</a> */}
                            <span className="text-xs ml-2x text-gray-600 pt-1 md:pt-0">
                                <ReactTimeAgo date={post.createdAt} locale="en-US" />
                            </span>
                        </div>
                    </div>
                    <div className="flex self-start md:self-end  items-center self-stretch">

                        <LikeIcon className={'text-gray-500 md:w-3'} />
                        <span className="text-sm md:text-xs ml-1 mr-4 md:mr-2 text-gray-600">
                            {Utils.comma(post.likes.length)}
                        </span>
                        <ChatsIcon fillColor={'#6b7280'} strokeColor={'#ffffff'} className={'w-5 md:w-4 text-gray-500'} />
                        <span className="text-sm text-gray-600 md:text-xs ml-1 md:mr-4">
                            {Utils.comma(post.count_of_replies)}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};



export default PostListItem;